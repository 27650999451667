var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthSlot',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Expired Password ")]},proxy:true},{key:"form",fn:function(){return [_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1 justify-content-center"},[_c('b-col',{attrs:{"lg":"9"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-email"}},[_vm._v("Email")])]),_c('validation-provider',{attrs:{"name":"Email","vid":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-email","type":"email","state":errors.length > 0 ? false : null,"name":"reset-password-email","placeholder":"Enter Email"},model:{value:(_vm.emailUser),callback:function ($$v) {_vm.emailUser=$$v},expression:"emailUser"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-email"}},[_vm._v("Current Password")])]),_c('validation-provider',{attrs:{"name":"Current Password","rules":"required","vid":"currentPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}})],1)]),_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-current","type":_vm.password3FieldType,"state":errors.length > 0 ? false : null,"name":"reset-password-current","placeholder":"············"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword3Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mb-1 justify-content-center"},[_c('b-col',{attrs:{"lg":"9"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-email"}},[_vm._v("New Password")])]),_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|confirmed:Confirm Password|specialpassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}})],1)]),_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false : null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-email"}},[_vm._v("Confirm Password")])]),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password|specialpassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}})],1)]),_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false : null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.loading)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Reset Password ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"type":"button","block":""},on:{"click":_vm.goToLogin}},[_vm._v(" Cancel ")])],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"primary","disabled":"","block":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Loading... ")],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"type":"button","block":"","disabled":""}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }