<template>
  <AuthSlot>
    <template #title> Expired Password </template>
    <template #form>
      <validation-observer ref="simpleRules" #default="{ invalid }">
        <b-form
          class="auth-reset-password-form mt-2"
          method="POST"
          @submit.prevent="validationForm"
        >
          <b-row class="mt-1 justify-content-center">
            <b-col lg="9">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="login-email" class="label-login">Email</label>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="Email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-email"
                      v-model="emailUser"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-email"
                      placeholder="Enter Email"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between text-white">
                  <label for="login-email" class="label-login"
                    >Current Password</label
                  >
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Current Password"
                  :rules="`required`"
                  vid="currentPassword"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend>
                      <div class="input-group-text">
                        <feather-icon icon="LockIcon" size="18"></feather-icon>
                      </div>
                    </b-input-group-prepend>

                    <b-form-input
                      id="reset-password-current"
                      v-model="currentPassword"
                      :type="password3FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-current"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword3Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1 justify-content-center">
            <b-col lg="9">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="login-email" class="label-login"
                    >New Password</label
                  >
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|confirmed:Confirm Password|specialpassword"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend>
                      <div class="input-group-text">
                        <feather-icon icon="LockIcon" size="18"></feather-icon>
                      </div>
                    </b-input-group-prepend>

                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between text-white">
                  <label for="login-email" class="label-login"
                    >Confirm Password</label
                  >
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password|specialpassword"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend>
                      <div class="input-group-text">
                        <feather-icon icon="LockIcon" size="18"></feather-icon>
                      </div>
                    </b-input-group-prepend>

                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row v-if="!loading">
                <b-col cols="6">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    Reset Password
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button type="button" block @click="goToLogin">
                    Cancel
                  </b-button>
                </b-col>
              </b-row>

              <b-row v-else>
                <b-col cols="6">
                  <b-button variant="primary" disabled block>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button type="button" block disabled> Cancel </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </AuthSlot>
</template>

<script>
/* eslint-disable global-require */
import useJwt from "@/auth/jwt/useJwt"
import AuthSlot from "@/views/pages/authentication/AuthSlot.vue"
import { required } from "@validations"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  props: {
    email: {
      type: String,
      default: "",
    },
    correct_password: {
      type: String,
      default: "",
    },
  },
  components: {
    AuthSlot,
  },
  mounted() {
    if(this.email == "" || this.correct_password == ""){
      this.$router.push({
        name: "auth-login",
      })
    }
    this.emailUser = this.email
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      currentPassword: "",
      required,
      emailUser: "",
      password1FieldType: "password",
      password2FieldType: "password",
      password3FieldType: "password",
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    token() {
      return this.$route.params.token
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password"
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password"
    },
    togglePassword3Visibility() {
      this.password3FieldType =
        this.password3FieldType === "password" ? "text" : "password"
    },
    goToLogin() {
      this.$router.push({ name: "auth-login" })
    },

    async validationForm() {
      let valid = await this.$refs.simpleRules.validate()
      if (valid) {
        this.loading = true
        try {
          const data = await useJwt.saveNewPassword({
            token: this.token,
            new_password: this.password,
            email: this.emailUser,
            confirm_password: this.cPassword,
            current_password: this.currentPassword,
          })

          if (data.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "CheckIcon",
                variant: "success",
                text: "Password reset successfully",
              },
            })
            this.loading = false
            this.$router.push({ name: "auth-login" })
          }
        } catch (error) {
          this.loading = false
          this.$refs.simpleRules.setErrors({
            currentPassword: error.response.data.error,
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "EditIcon",
              variant: "danger",
              text: error.response.data.error,
            },
          })
        }
      }
    },
  },
}
</script>
